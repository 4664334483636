import { DataGrid, GridColumns } from '@material-ui/data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { GoPencil } from 'react-icons/go';
import { ParcelaFinanceiro } from '~/pages/EmissaoNFE/protocols';
import { useEmissaoNFE } from '~/pages/EmissaoNFE/EmissaoNFEContext';
import { formatCurrencyAsText, getDataUtil } from '~/utils/functions';
import { ButtonRow, TableContainer } from './styles';
import Swal from 'sweetalert2';
import { MdDeleteForever } from 'react-icons/md';
import { deleteItens } from '~/utils/masterDetail';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';
import { usePedidoVenda } from '~/pages/PedidoVenda/PedidoVendaContext';

export const TableFinanceiro: React.FC = () => {
  const {
    parcelasRows,
    setParcelasRows,
    setIsFormFinanceiroEditing,
    formFinanceiro: { setValue },
    isUpdate,
    masterDetail,
    setMasterDetail,
  } = usePedidoVenda();

  const columns: GridColumns = [
    {
      field: 'num_registro',
      headerName: 'Num. Registro',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'num_condicao',
      headerName: 'Prazo',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'cod_condicao',
      headerName: 'Cód. Condicao',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_condicao',
      headerName: 'Condição',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'dta_vencimento',
      headerName: 'Vencimento',
      width: 150,
      disableColumnMenu: true,
      renderCell: (row: any) => {
        const dateValue =
          typeof row.value === 'string'
            ? row.value
            : row.value.format('YYYY-MM-DD');
        return (
          <>
            {moment(createDateWithoutTimezone(dateValue)).format('DD/MM/YYYY')}
          </>
        );
      },
    },
    {
      field: 'val_parcela',
      headerName: 'Valor',
      width: 100,
      disableColumnMenu: true,
      renderCell: (row: any) => {
        return (
          <>
            <span>{formatCurrencyAsText(row.value)}</span>
          </>
        );
      },
    },
    {
      field: 'cod_finalizadora',
      headerName: 'Cód. Finalizadora',
      hide: true,
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_finalizadora',
      headerName: 'Finalizadora',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <ButtonRow
              type="button"
              onClick={() => onEditItem(row.uuid)}
              disabled={false}
              title="Editar"
            >
              <GoPencil size={20} style={{ color: '#72ab90' }} />
            </ButtonRow>
            <ButtonRow
              type="button"
              onClick={() => onRemoveItem(row.uuid)}
              disabled={false}
              title="Remover"
            >
              <FaTrash size={20} style={{ color: '#e63c3c' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const onEditItem = (uuid: string) => {
    const reg = parcelasRows.find((parcela) => parcela.uuid === uuid);
    if (reg) {
      setValue('dta_vencimento', getDataUtil(reg.dta_vencimento));
      setValue('val_financeiro', formatCurrencyAsText(reg.val_parcela));
      setValue('num_condicao', reg.num_condicao);
      setValue('finalizadora', {
        value: reg.cod_finalizadora,
        label: reg.des_finalizadora,
      });
      setValue('condicao', {
        value: reg.cod_condicao,
        label: reg.des_condicao,
      });
      setIsFormFinanceiroEditing({ isEdit: true, uuid });
    }
  };

  const onRemoveItem = async (uuid: string) => {
    const result = await Swal.fire({
      title: ``,
      text: 'Deseja realmente remover essa parcela?',

      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    });

    if (result.isConfirmed) {
      const parcelasDetail: any[] = await deleteItens(
        'financeiro',
        uuid,
        masterDetail,
        setMasterDetail,
      );

      setParcelasRows(parcelasDetail);
    }
  };

  return (
    <TableContainer>
      <DataGrid
        rowsPerPageOptions={[5]}
        rows={parcelasRows}
        columns={columns}
        pageSize={5}
        disableColumnSelector
        disableColumnFilter
        disableSelectionOnClick
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado...',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar columnas',
        }}
      />
    </TableContainer>
  );
};
