import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import {
  CalculoCondicao,
  CalculoResponse,
  InputAsyncSelect,
  InputDate,
  InputMoney,
} from '~/components/NovosInputs';
import { ClearButton, ConfirmButton } from '~/components/Buttons';

import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import Separator from '~/components/Separator';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';

import { addUpdate, insertOrUpdate } from '~/utils/masterDetail';
import { nanoid } from 'nanoid';
import { ButtonItens } from '~/pages/NFOutrasOperacoes/screens/Nfe/styles';
import { FaSync } from 'react-icons/fa';
import { usePedidoVenda } from '~/pages/PedidoVenda/PedidoVendaContext';
import { LojaContext } from '~/context/loja';

const MySwal = withReactContent(Swal);

export const FormParcelas: React.FC = () => {
  const { codLoja, loja } = useContext(LojaContext);
  const [numParcela, setNumParcela] = useState(1);
  const [datasNaoInformadas, setDatasNaoInformadas] = useState<boolean>(false);

  const {
    formFinanceiro: {
      getValues,
      register,
      handleSubmit,
      control,
      reset,
      setValue,
      watch,
      clearErrors,
      formState: { errors },
      formState,
    },
    isFormFinanceiroEditing,
    setIsFormFinanceiroEditing,
    setCurrentTab,
    produtosRows,
    parcelasRows,
    setParcelasRows,
    isUpdate,
    setMasterDetail,
    masterDetail,
    handleRecalculaFinanceiro,
    valorTotal,
  } = usePedidoVenda();

  const finalizadora = watch('finalizadora');

  useEffect(() => {
    const valorPedido = transformAsCurrency(valorTotal || 0);
    if (parcelasRows.length !== 0) {
      const totalParcelas = parcelasRows.reduce(
        (acc, parcela) => acc + parcela.val_parcela,
        0,
      );
      const resultado = valorPedido - totalParcelas;
      setValue(
        'val_financeiro',
        formatCurrencyAsText(resultado > 0 ? resultado : 0),
      );
    } else {
      setValue('val_financeiro', formatCurrencyAsText(valorPedido));
    }
  }, [parcelasRows, valorTotal]);

  const handleAddParcela = handleSubmit(
    async (data: any) => {
      const dataEmissao = getValues('dta_emissao');
      const dataEntrada = getValues('dta_saida');

      if (dataEmissao === '' || dataEntrada === '') {
        setDatasNaoInformadas(true);
      }

      const exists = parcelasRows.filter(
        (parcela) =>
          parcela.num_condicao === data.num_condicao &&
          parcela.cod_condicao === data.condicao.value &&
          parcela.cod_finalizadora === data.finalizadora.value &&
          parcela.uuid !== isFormFinanceiroEditing.uuid,
      );
      if (exists.length > 0) {
        return toast.warning('Já existe uma condição com essas informações.');
      }

      const formatedParcela: any = {
        uuid: isFormFinanceiroEditing.isEdit
          ? isFormFinanceiroEditing.uuid
          : nanoid(),
        id: isFormFinanceiroEditing.isEdit
          ? isFormFinanceiroEditing.uuid
          : nanoid(),
        num_condicao: data.num_condicao,
        cod_condicao: data.condicao.value,
        des_condicao: data.condicao.label,
        dta_vencimento: data.dta_vencimento,
        val_parcela: transformAsCurrency(data.val_financeiro),
        cod_finalizadora: data.finalizadora.value,
        des_finalizadora: data.finalizadora.label,
        num_registro: numParcela,
      };
      setNumParcela(numParcela + 1);
      const parcelasDetail: any[] = await insertOrUpdate(
        'financeiro',
        formatedParcela,
        masterDetail,
        setMasterDetail,
      );
      setParcelasRows(parcelasDetail);
      reset({
        num_condicao: 30,
        condicao: {
          value: 2,
          label: 'DD - DIAS DA DATA',
          cod_condicao: 2,
          des_condicao: 'DIAS DA DATA',
          des_definicao: 'DD',
        },
        finalizadora: '',
        dta_vencimento: '',
        val_financeiro: '',
      });
    },
    (err) => {
      console.log(err);
    },
  );

  const handleVencimento = useCallback(
    (condicaoData: CalculoResponse) => {
      if (condicaoData.error === 'Datas Devem ser informadas.') {
        setDatasNaoInformadas(true);
        return;
      }
      setValue(
        'dta_vencimento',
        format(new Date(condicaoData.vencimento), 'yyyy-MM-dd'),
      );
      setValue('num_condicao', condicaoData.numDias);
      setValue('condicao', condicaoData.condicao);
    },
    [setValue],
  );

  return (
    <>
      <Row>
        <Col md={10} sm={12}>
          <Separator labelText="Financeiro" sidePadding="0 0" />
        </Col>
        <Col md={2} sm={12}>
          <ButtonItens
            type="button"
            onClick={() => handleRecalculaFinanceiro()}
          >
            <FaSync color="#28a745" />
            <span>(Re) Calcular</span>
          </ButtonItens>
        </Col>
      </Row>
      <Row className="d-flex align-items-center position-relative">
        <Col sm={12} md={3}>
          <InputAsyncSelect
            label="Finalizadora"
            maxLength={50}
            placeholder="Selecione..."
            name="finalizadora"
            register={register}
            isError={!!errors.finalizadora}
            control={control}
            disabled={false}
            listWidth="300px"
            changeSelected={(selected) => {
              clearErrors('finalizadora');
              setValue('finalizadora', selected);
            }}
            api={{
              route: '/finalizadora',
              method: 'get',
              bodyParams: {
                codLoja,
              },
              fields: ['cod_finalizadora', 'des_finalizadora'],
              dependsOf: ['codLoja'],
              messageForDependsOf:
                'É necessário selecionar a loja na capa da NF',
              searchBeforeFilter: true,
            }}
          />
        </Col>
        <CalculoCondicao
          name="condicaoEmissãoNF"
          register={register}
          getValues={getValues}
          control={control}
          setValue={setValue}
          formState={formState}
          watch={watch}
          dataEmissao={getValues('dta_emissao')}
          dataEntrada={getValues('dta_saida')}
          disabled={false}
          fieldToWatch="finalizadora"
          changeCondicao={(result: any) => {
            handleVencimento(result);
          }}
        />
        <Col sm={12} md={2}>
          <InputDate
            register={register}
            label="Vencimento"
            name="dta_vencimento"
            control={control}
            disabled={false}
            isError={!!errors.dta_vencimento}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Valor"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_financeiro"
            register={register}
            disabled={false}
            isError={!!errors.val_financeiro}
          />
        </Col>
        <Col md={12} sm={12} className="d-flex mt-2 justify-content-end">
          <ConfirmButton onClick={() => handleAddParcela()} disabled={false} />
          <ClearButton
            onClick={() => {
              reset({
                num_condicao: 30,
                condicao: {
                  value: 2,
                  label: 'DD - DIAS DA DATA',
                  cod_condicao: 2,
                  des_condicao: 'DIAS DA DATA',
                  des_definicao: 'DD',
                },
                finalizadora: '',
                dta_vencimento: '',
                val_financeiro: '',
              });
              setIsFormFinanceiroEditing({ isEdit: false, uuid: undefined });
            }}
            disabled={false}
          />
        </Col>
      </Row>
    </>
  );
};
